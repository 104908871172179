@charset "utf-8";

@import '_variables';

// SYRUP LOGIN
#login,#login .btn,.login-page{font-family:"Helvetica Neue",Helvetica,Arial,sans-serif!important}#email-message,#password-message{position:absolute;left:300px;white-space:nowrap}.login-page{background:#f8f8f8}#login{margin:auto;padding:80px 0 0;width:320px}#login img{margin:0 12px 30px}#login form{border-radius:6px;box-shadow:1px 1px 6px rgba(0,0,0,.1);padding:24px;background:#f1840a;margin-bottom:15px}#login .form-control{box-shadow:none!important;border-color:#fff!important}#login .btn-orange{background-color:#e67e22;color:#fff;border:2px solid #fff}#login .btn-orange:hover{background-color:#f38b2f;color:#fff}#login form p{margin:0 0 20px;font-size:13px}#login a{border-bottom:1px dotted #ccc}#email-message:empty,#password-message:empty{opacity:0;transition:all 0s ease 0s}#email-message{top:30px}#password-message{top:6px}.warning{background-color:#e74c3c;border-radius:4px;box-shadow:2px 2px 2px rgba(0,0,0,.2);color:#fff;font-size:12px;margin-left:10px;opacity:1;padding:4px 8px;transition-duration:.5s}#login .btn{text-transform:none;letter-spacing:0}


html {
  font-size: 62.5%;
  height: 100%; }
body {
  font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
  font-weight: 400;
  font-family: $font2; 
  height: 100%;
  // margin-top: -80px;

  &.pushy-open-right {
    overflow-y: hidden;
  }
}
* {
  outline:none!important;
}

/*========== TYPOGRAPHY ==========*/
h1, h2, h3, h4, h5, h6 {
  font-family: $font1 !important;
  color: #000;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  color: $red;
  position: relative;
  text-rendering: optimizeLegibility;
}
h1 { font-size: 3.4rem; line-height: 1.5;}
h2 { font-size: 3.2rem; line-height: 1.4;}
h3 { font-size: 2.6rem; line-height: 1.3;}
h4 { font-size: 2.2rem; line-height: 1.2; }
h5 { font-size: 2rem; line-height: 1.2;}
h6 { font-size: 1.6rem; line-height: 1.6;}

/* Larger than phablet */
@media (min-width: 550px) {
  h1 { font-size: 3.8rem; }
  h2 { font-size: 3.4rem; }
  h3 { font-size: 3rem; }
  h4 { font-size: 2.6rem; }
  h5 { font-size: 2rem; }
  h6 { font-size: 1.5rem; }
}
/*========== BTNS ==========*/
.btn {
  border-radius:0;
  padding:10px 15px;
}
.btn-pill-red {
  border:1px solid $red;
  background-color:#fff;
  color:$red;
  border-radius: 6px;
  font-family: $font1;
  &:hover {
    color:#fff;
    background-color:$red;
  }
}
/*========== FORMS ==========*/
.form-control {
  box-shadow:none!important;
  border-radius:0;
  &:focus, &:active {
    border-color:$red;
  }
}
/*========== HELPERS ==========*/

.ctn-padding-top {
  padding-top: 180px !important;
}

.kambor {
  border: solid 2px red;
}


nav {
  ul {
    margin:0;
    padding:0;
    list-style: none;
  }
}
.t {
    display: table;
    table-layout: fixed;
    width: 100%;
    &.nf {
      table-layout:auto!important;
    }
    .td {
      display: table-cell;
      vertical-align: middle;
    }
}
.row.hg {
    margin-left: -8px;
    margin-right: -8px;
    [class*="col-"] {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.p-t-0 {
  padding-top:0!important;
}
.p-b-0 {
  padding-bottom:0!important;
}

/* centered columns styles */
.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}
/*========== GLOBAL CONTAINERS ==========*/
.container {
  position: relative;
}
.ctn {
  padding:30px 0;
  &.bg--grey{
    background-color:#eee;
  }
}
.fl__row{

  display: -webkit-box;  
  display: -moz-flexbox;  
  display: -ms-flexbox; 
  display: -webkit-flex; 
  display: -moz-flex;     
  display: flex;

  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  -webkit-box-align: stretch; 
  -moz-box-align: stretch; 
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  justify-content: flex-start;
}
.fl__item {
  display: -webkit-box;  
  display: -moz-flexbox;  
  display: -ms-flexbox; 
  display: -webkit-flex; 
  display: -moz-flex;     
  display: flex;
}
.socialIcons {
  padding:40px 0px;
  margin-top: 30px;
  margin-left:20px;
  margin-right:20px;
  display: flex;
  flexwrap:nowrap;
  justify-content: left;
  border: solid 1px rgba(255,255,255,0.2);
  border-left:0;
  border-right:0;
  .social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height:30px;
    width:30px;
    border-radius:20px;
    padding:5px;
    font-size: 1.8rem;
    margin:0 5px;
    color: $red;
    transition-duration: 300ms;
    &.facebook {
      background-color:#fff;
      &:hover {
        background-color:lighten(#3a5795,5%);
      }
    }
    &.twitter {
      background-color:#fff;
       &:hover {
        background-color:lighten(#55acee,5%);
      }
    }
    &.linkedin {
      background-color:#fff;
       &:hover {
        background-color:lighten(#0077b5,5%);
      }
    }
    &.youtube {
      background-color:#fff;
       &:hover {
        background-color:lighten(#BF2626,5%);
      }
    }
  }
}
/*========== HEADER ==========*/

//header transparency and size
.headv1 {
  background-color:$red;
  border-bottom:1px solid rgba(#fff,0.2);
  z-index:999;
  position:relative;

  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;

  backface-visibility:hidden;
  -webkit-backface-visibility: hidden;

  .logo-holder {
    background-image:url('/img/logo_mob.png');
    background-size:100% auto;
    float:left;

    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    backface-visibility:hidden;
    -webkit-backface-visibility: hidden;

    height: 55px;
    margin: 10px 20px;
    width: 98px;
  }

  &.headv1--transparent {
    background-color:transparent;
    border-bottom:0;
  }
}

.stuck {
  .headv1 {
    border-bottom:1px solid rgba(#fff,0.2);
    background-color:$red!important;
  }
}




//transparent header on home

.headv1__dropdown--search {
  background-color: $pink;
  max-width: 200px;
  border:none;
  right: 15px;
  left: auto;
  .form-control{
    box-shadow:none!important;
    border:1px solid #fff;
  }
  .btn {
    height: 34px;
    width: 34px;
    padding:0;
    background-color: $red;
    i {
      padding:0;
      color: #fff;      
    }    
  }
}


.headv1 {
  width: 100%;
}


.headv1__top {
  position:relative;
  .container {
    padding:0;
  }
}
// .headv1__logo {
//   display: inline-block;
//   width: 80px;
//   position:absolute;
//   left: 50px;
//   top: 8px;
//   transition-duration: 0.3s;
  
// }




.headv1__topmenu {
  display: inline-block;
  float: right;
  height: 60px;
  ul {
    display: table;
    table-layout: fixed;
    height: 100%;
    //width: 120px;
    li {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      > a {
        height: 100%;
        text-align:center;
        font-size: 1.8rem;
      }
      .dropdown-menu {
          border: medium none;
          border-radius: 0;
          left: auto;
          margin: 0;
          right: 0;
          width: 240px;
      }
    }
  }
}

// header dropdowns
.headv1__dropdown {
  padding:15px;
}

// basket dropdown
.product-table__item {
  table-layout: auto;
  .img {
    width: 40px;
  }
  .details{
    padding: 0 10px;
    .details__item {
      display: block;
      font-size: 1.2rem;
      &.details__item--title {
        font-size: 1.3rem;
        font-weight: 600;
      }
    }
  }
  & + .product-table__item {
    margin-top:10px;
    padding-top:10px;
    border-top:1px solid #eee;
  }
  &:last-child {
    margin-bottom:15px;
  }
}

.headv1__topmenu--ul1 {
  
  width:120px !important;
}

.headv1__topmenu--div1 {
  float: right;
  /* border: solid 2px red; */
  margin-top: 26px;
  
}



.headv1__div1-item {
  font-size: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $white;
  color: $red;
  transition-duration: 0.5s;
  &:hover {
    background-color: transparent;
    color: $white;
    
  }
}



.headv1__topmenu--div2 {
  @include flexbox();
  @include flexwrap(nowrap);
  // display:flex;
  // flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right:15px;
  float: right;
  a {
    text-decoration: none;
  }
}

.headv1__topmenu--div2-item {
  @include flexbox();
  justify-content: center;  
}


.headv1__div2-icon {
  @include flexbox();
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  
  color: $white;
  // margin-left: 10px;
  transition-duration: 0.5s;
  cursor: pointer;
  
  &:hover {
    background-color: #fff;
    
    color: $red;
  }
}


.headv1--large-icons {
  padding-left: 10px; 
}
  


.headv1__topmenu--div3 {
float: right;
  margin-top:30px;
}
.textMenu {
  //width: 300px!important;
  margin-right: 20px;
  li {
    padding:0 10px;
    a {
      font-size: 1.5rem!important;
      color:#fff;
      &:hover {
        text-decoration: none!important;
      }
    }
  }
}





// pull out menu

.pushy-right ul {
  &:first-child {
     margin-top: 30px;
  }
}

.side-angle-right {
  margin-right: 10px;
}

.menu-section {
  h5 {
    padding-left:25px;
    color: $white;
    font-weight: 400;
    margin-bottom: 10px;
  }
  a {
    padding:5px 30px !important;
  }
  &.menu-section--address {
    h5 {
      font-size: 1.8rem;
      margin:0 0 10px;
      font-weight: 500;
    }
  }
}

.pushy {
  font-family: $font3;
  font-size: 1.6rem;
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: $red;
  overflow: auto;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */
}
.pushy a {
  display: block;
  color: #fff;
  padding: 10px 30px;
  text-decoration: none;
  outline: 0;
}
.pushy a:hover {
  color: #fff;
  background-color: $pink;
}
.pushy ul:first-child {

}
.pushy-menu--dark > a{
  background-color:darken($red,2%);
}
.pushy.pushy-left {
  left: 0;
}
.pushy.pushy-right {
  right: 0;
}

/* Menu Movement */
.pushy-left {
  -webkit-transform: translate3d(-300px, 0, 0);
  -ms-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0);
}

.pushy-open-left #container,
.pushy-open-left .push {
  -webkit-transform: translate3d(300px, 0, 0);
  -ms-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0);
}

.pushy-right {
  -webkit-transform: translate3d(300px, 0, 0);
  -ms-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0);
}

.pushy-open-right #container,
.pushy-open-right .push {
  -webkit-transform: translate3d(-300px, 0, 0);
  -ms-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0);
}

.pushy-open-left .pushy,
.pushy-open-right .pushy {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Menu Transitions */
#container,
.pushy,
.push {
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
}
/* Site Overlay */
.site-overlay {
  display: none;
}
.pushy-open-left .site-overlay,
.pushy-open-right .site-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: fade 500ms;
  animation: fade 500ms;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Submenu Appearance */
.pushy-submenu {
  /* Submenu Icon */
}
.pushy-submenu ul {
  padding-left: 15px;
  transition: max-height 0.2s ease-in-out;
}
.pushy-submenu ul .pushy-link {
  transition: opacity 0.2s ease-in-out;
}
.pushy-submenu > a {
  position: relative;
}
.pushy-submenu > a::after {
  content: '\f105';
  font-family: 'FontAwesome';
  display: block;
  width: 8px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: transform 0.2s;
}

/* Submenu Movement */
.pushy-submenu-closed ul {
  max-height: 0;
  overflow: hidden;
}
.pushy-submenu-closed .pushy-link {
  opacity: 0;
}

.pushy-submenu-open {
  /* Submenu Icon */
}
.pushy-submenu-open ul {
  max-height: 1000px;
}
.pushy-submenu-open .pushy-link {
  opacity: 1;
}
.pushy-submenu-open a::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.no-csstransforms3d .pushy-submenu-closed ul {
  max-height: none;
  display: none;
}
/*========== BANNERS ==========*/
.main-slider {
  position:relative;
  .slick-dots {
    position:absolute;
    bottom:0;
    text-align:center;
    width: 100%;
  }
  .slick-dots li.slick-active button::before {
      background-color:rgba(#fff,1);
  }
  .slick-dots li button::before {
      background-color:rgba(#fff,0.3);
  }
  .slick-prev, .slick-next {
    height: 50px;
  }
  .slick-prev {
    left:30px;
  }
  .slick-next {
    right:30px;
  }
  .slick-prev:before,.slick-next:before {
    font-size: 50px;
    color: #fff;
  }
}
.banner{
  height: 520px;
  background-image:url('/images/banner.jpg');
  background-size: cover;
  width:100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  // &::after {
  //   background-color: $blue;
  //   content: "";
  //   height: 100%;
  //   left: 0;
  //   opacity: 0.7;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  // }
}
.banner__content {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  z-index: 5;
  width: 100%;
  h3 {
    color:#fff;
    font-size: 3rem;
    font-weight: 400;
  }
  p {
    color:#fff;
    margin:0 0 20px;
    font-size: 1.4rem;
    font-weight: 300;
  }
  
}
#homepage .banner__content {
  top:60%;
}

.banner__btn {
  margin-top: 30px;
  font-family: $font1;
  font-weight: 400;
  font-size: 2.4rem;
  color: #fff;
  transition-duration: 0.2s;
  border: solid 1px $grey;
  border-radius: 6px;
  
  padding: 6px 24px 6px 24px;
  &:hover {
    color: $pink;
    border-color: $pink;

  }

}

/*========== FEATURED ITEMS ==========*/

.featured__btn {
  margin-top: 30px;
  font-family: $font1;
  font-weight: 400;
  font-size: 2rem;
  color: #fff;
  transition-duration: 0.5s;
  border: solid 1px $grey;
  border-radius: 6px;    
  padding: 6px 24px 6px 24px;
  &:hover {
    color: $pink;
    border-color: $pink;
    background-color: $white;
  }
}

.ctn--feat {
    background-color: $red;
    background-image: url(/img/grad.png);
    background-repeat: repeat;
    // .container {
    //   width: 1170px;
    // }
    .fl__item {
      margin: 0px;
      padding: 0px;


    }
  }

.fi__row {
  .fl__item {
    align-items: flex-end;
    margin: 0px;
    padding: 0px;
  }
}

//.ctn--feat{
  .fi {
    //margin: 0 0 0px;
    .fi__content {   
      //border:2px solid rgba(255,255,255,0.2);
      padding:15px 15px 45px;
      width: 100%;
      position:relative;
      a:hover {
        text-decoration: none;
      } 
      .btn {
        position:absolute;
        bottom:12px;
        right:12px;
      }       
    }
    .fi__heading {
      //margin:10px 0;
      font-size: 1.6rem;
      margin-bottom: 10px;
      //color: $white;

    }
    .fi__text {
        //color: $white;
    }

    .fi__excerpt {
      margin:0;
      color:#fff;
      font-size: 1.3rem;
    }
  }  
//}





.fi__heading-hardcoded {
  span {
    color: $white;
    display:block;
  }
}
 .featured__button {
  display: flex;
  justify-content: center;
 }
  
.ctn--feat {
  .fi__heading {
    color: $white !important;
  }
}

.ctn--feat2 {
  .fi__content {
    display: flex;
    flexwrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
  }
  .fi__heading {
    color: $red;
    font-size: 2rem !important;
    padding: 15px 0px;
    text-align: center;
  }
  .fi__text {
    text-align: center;
  }
}


/*========== FOOTER ==========*/


footer {
  position:relative;
  z-index: 1;
  //margin-top: 50px;
}

.footer__main-container {
  padding-top: 0px !important;
  padding-bottom: 0 !important;
  
  
}

.footer__second-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer__upper-container {
  background-color: $pink;
  padding-bottom: 15px;
  

}

.footer__content-container {
  padding-top: 20px;

}

/* .footer__collumns {
  border: solid 2px red;p

  display: block; 
  }*/ 
 
  


 .footer__contact {

  font-family: $font1;
  font-size: 3rem;
  text-align: left;
  margin-top: 30px;
} 

.footer__social {
  font-family: $font1;
  font-size: 3rem;
  margin-top: 30px;
}

.footer__contact--address {
  margin-top: 30px;
  span {
    display: block;  
    &:nth-child(1):before {
      font-family: FontAwesome;
      content: "\f041";
      padding-right: 15px;
    }
    &:nth-child(2):before {
      font-family: FontAwesome;
      content: "\f095";
      padding-right: 12px;
    }
    &:nth-child(3):before {
      font-family: FontAwesome;
      content: "\f1ac";
      padding-right: 10px;
    }
    &:nth-child(4):before {
      font-family: FontAwesome;
      content: "\f003";
      padding-right: 10px;
    }
  }
}

.footer__contact--phone {
  text-align: left;
  width: 50%;
  font-weight: 400;
  font-size: 1.2rem;
}

.footer__contact--phone span {
  display: block;
}

.footer__contact--copyright {
  font-family: $font2;
  margin-top: 30px;
  /* text-align: left; */
  width: 100%;
  font-weight: 400;
  font-size: 1.2rem;
}

.footer__contact--copyright div:nth-child(1) {
  border-left: 0px;
}

 /* .footer__concept-icon {
  margin-bottom: 20px;  
}  */



.footer__social-icons {

  padding-top:20px;
  clear:both;

  /* float:left; */

}

.footer__social-icons-icons {
  /* float:left; */
  text-align: center;
  clear:both;
  display: inline;
  border-spacing: 10px 10px ;
  border-collapse: separate;
  font-size: 20px;
  
  
}

.footer__social-link {
  width: 36px;
  height: 36px;
  margin: 0 0px;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
  color: $pink !important;
}

.footer__social-icons-bg {
  width:50px;
  height:50px;
  color: $red;
  background-color: #fff;
  border-radius: 50%;
  display: inline;

}

.verticalline {
    border-left: solid 1px #fff;
    display: inline;
    padding-left: 4px;
    padding-right: 4px;


}

.footer__tweets {
  text-align: left;
  margin-top: 30px;
}

.footer__tweets--feed {
  font-family: $font2;
  margin-top: 30px;
  text-align: left;
  width: 100%;
  font-weight: 400;
  font-size: 1.2rem;
}

.footer__tweets--feed span {
  display: block;
}


.footer__logo {
  text-align: center;
  img {
  margin-top: 60px;
  
  }
}








.footer-top {
  background-color:$red;
  text-align: left;
  color: #fff;
  a {
    color: #fff;
  }
}
.footer-top__logo {
  width: 100px;
  margin: 0 auto 20px;
}
.footer-top__detail {
  font-size: 1.8rem;
  font-weight: 800;
  display: block;
  + .footer-top__detail {
    margin:20px 0;
  }
}
.footer-top__detail--email {
  a {
    padding:10px;
    border:3px solid #fff;
    color: #fff;
    display: inline-block;
    text-decoration: none!important;
  }
}
.footer-top__detail--copy {
  margin-top:15px;
  font-size: 1.3rem;
}

// iCandy Icon font
@font-face {
    font-family: 'iCandy-icons';
    src:    url('/fonts/iCandy-icons.eot?lkx0x');
    src:    url('/fonts/iCandy-icons.eot?lkx0x#iefix') format('embedded-opentype'),
        url('/fonts/iCandy-icons.ttf?lkx0x') format('truetype'),
        url('/fonts/iCandy-icons.woff?lkx0x') format('woff'),
        url('/fonts/iCandy-icons.svg?lkx0x#iCandy-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

i.icd {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iCandy-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icd-icandy-icon:before {
    content: "\e901";
}
.icd-syrup-icon:before {
    content: "\e900";
}


.ftr__signature {
  text-align: center;
  background-color:darken($red,2%);
  color: #fff;
  padding:10px 0;
  font-size: 1.3rem;
  a {
    color: #fff;
  }
  i.icd {
    font-size: 2.2rem;
    margin-right: 5px;
    vertical-align: -4px;
  }
}
/*========== HOME ==========*/

.download-container {
  background-color: $pink;
}

.download-body {  
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flexwrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h4 {
    color: $white;
    padding: 20px;
    font-weight: 300;
    margin: 0;
  }
  &__picture {
  }
  &__button {
    font-family: $font3;
    border: solid 1px $white;
    border-radius: 8px;
    color: $white;
    transition-duration: 0.5s;
    min-width: 180px;
    &:after {
      font-family: FontAwesome;
      content: '\f1c1';
    }
    &:hover {
      color: $pink;
      border-color: $pink;
      background-color: $white;
    }
  }
}


.pink-boxes { 
  
  padding-top: 15px;
  padding-bottom: 15px;
  color: $white;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  & > .pink-boxes__item-wrap {
   //display: flex;
   //flex-direction: column;
   //align-items:stretch;
   padding: 15px 15px;
   //width: 100%;

   -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
     -moz-box-flex: 1;         /* OLD - Firefox 19- */
     width: 33%;               /* For old syntax, otherwise collapses. */
     -webkit-flex: 1;          /* Chrome */
     -ms-flex: 1;              /* IE 10 */
     flex: 1;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */

   
   

  }
}

.pink-boxes__item {
  
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */





  width: 100%;
  height:100%;
  background-color: $pink;
  border-radius: 5px;
  //justify-content: center;
  padding: 30px 20px 30px 20px;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;

  //margin-top: 30px;
  margin-bottom: 30px;
  &__btn {
    margin-top: auto;
    font-family: $font1;
    font-weight: 400;
    font-size: 2rem;
    color: #fff;
    transition-duration: 0.5s;
    border: solid 1px $grey;
    border-radius: 6px;    
    padding: 6px 24px 6px 24px;
    &:hover {
      color: $pink;
      border-color: $pink;
      background-color: $white;
    }
  }
  &__img {
    padding:0 35px;
    width: 100%;
    overflow:hidden;
  }
  &__title {
    padding-top: 20px;
    h3 {
      color: $white;
      font-size: 2rem;
    }
  }
  &__link {
    padding-top:10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom:10px;
    width: 100%;
    border-style: solid;
    border: solid 2px rgba(255,255,255,0.2);
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    text-align: left;      
    a {
        text-decoration: none;
        color: $white;
      }
    &:last-child {
      border: solid 2px rgba(255,255,255,0.2);
      border-left: 0px;
      border-right: 0px;      
    }
    &__container {     
      span {        
        &:nth-child(2) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          float: right;
          color: #000;
          background-color: $white;
          border-radius:50%;
          border: solid 2px $pink;
          transition-duration: 0.5s;
          &:hover {
            color: $white;  
            border-color: $white;
            background-color: $pink;
          }
        }
      }   
    }
  }
}


.angle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  float: right;
  color: $pink;
  background-color: $white;
  border-radius:50%;
  // border: solid 2px $pink;
  border:0;
  transition-duration: 0.2s;
  padding: 0;
  &:hover {
    border: solid 1px $white;
    color: $white;  
    background-color: transparent;
  }
}

.angle-button2 {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  float: right;
  color: $white;
  background-color: $red;
  border-radius:50%;
  // border: solid 2px $pink;
  border:0;
  transition-duration: 0.2s;
  padding: 0;
  &:hover {
    border: solid 1px $red;
    color: $red;  
    background-color: transparent;
  }

}


.angle-button-cta {
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 15px;
  
  color: $red;
  background-color: $white;
  border-radius:50%;
  border: solid 1px $red;
  //border:0;
  transition-duration: 0.2s;
  padding: 0;
  i {
    margin-top:5px;
  }
  &:hover {
    color: $white;
    background-color: $red;
    border-color: $white;
  }
}


/*========== CATEGORY ==========*/
 #job-container > .mix {
   display: none;
}

.filter {
  padding:5px 10px;
  text-decoration: none!important;
  border-bottom:2px solid #fff;
  color:$red!important;
  margin:10px 5px;
  font-size: 2rem;
  &.active {
    border-color:$red;
  }
}


.fi--news {
  .fi__img {
    margin-bottom:15px;
  }
}


.category-main {
  //display: flex;
  border-top: solid 1px rgba(0,0,0,0.2);
  padding: 15px 0px;
  
  width: 100%;  
  .fi__content {
    h5 {
    font-family: $font2 !important;
    font-weight: 600;
    color: $red;
    margin-bottom: 3px;
    }
    p {
      color: #000;
    }
  }
}



.category__btn {
  border:1px solid $red;
  color: $red;
  background-color:transparent;
  border-radius: 5px;
  font-family: $font3;
  font-size: 2rem;
   margin-bottom:5px;
  padding: 5px 20px 5px 20px;
  transition-duration: 0.2s;
  + .category__btn {
    margin-left:5px;

  }
  &:hover {
    background-color: $red;
    color: $white;
    border-color:$white;
  }
}

.container--category {
  display: flex;
  flexwrap: nowrap;


  flex-direction: column;
  h3 {
    color: $red;
  }
}

.category__left {
  //width: 65%
}
.category__right {

  padding-left: 30px;

  //max-width: 100px;
  //width: 25%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;  
  .job__ref div {
    font-weight: 700;
    color: $red;
    display: block;
    span{
      color:#000;
      font-weight:300;
    }
  }
  .job__apply-button{
    text-align: right;
      margin-top: 10px;
   margin-bottom: 10px;
  }
}

.category__tags {
  padding-top: 40px;
  display: inline;
  a {
    text-decoration: none;
    &:hover {
      color: $red;
      text-decoration: underline;
      h5 {
        color: $red;
      }
    }
  }
  h5 {
    padding: 10px 40px;
    display: inline;
  }
}

/*========== ARTICLE ==========*/

.breadcrumb-container {
    position: absolute;
    top: -45px;
    right: 0;
}
.breadcrumb {
  background: none;
  margin: 0;
  padding:0;
  color:  #ccc;
  font-size: 14px;
  font-weight: 300;
  > .active {
    color: #ccc;
  }
  > li + li:before {
    color:  #ccc;
    font-family: FontAwesome;
    content: "\f105";
    padding: 0 10px;
  }
  a {
    color:  #ccc;
  }
}



.job__details {
  padding:15px;
  background-color:$grey;
  margin:0 0 15px;
  border-radius: 10px;
  .job__details-row{
    display: block;
    margin:0 0 5px;
    strong {
      color:$red;
    }
  }
}


.article-sidebar {
  margin-top:30px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  background-color: $grey;
  border-radius: 10px;
  padding: 15px 30px;  
  h3 {
    color: $red;
  }
  h4 {
    color:$red;
  }
  p {
    color: #000;
  }
  &__inner {
    display: flex;
    width:100%;
    flexwrap:nowrap;
    justify-content: flex-start;
    border-bottom: solid 1px rgba(0,0,0,0.1);
    &:last-child {
        border-bottom:none; 
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      
      padding: 10px 0 20px;
      width: 100%;
    }
  }
}

.article__left-side {
  h1 {
    color: $red;
    font-size: 3rem;
  }
}

.container--article {
  h1 {
    color: $red;
  }
}

.related__title {
  margin: 10px 0 0;
  width:100%;
}
.relateditem__title {
  margin: 10px 0;
  font-size: 1.8rem;
  width:100%;
  a {
    color:$red;
  }
}

.apply-btn {
  width: 100%;
  margin:15px 0 0;
}


/*========== SLICES ==========*/











.slice {
  padding:30px 0;
  position: relative;
  .slice__btn {
    border:1px solid $bodycolor;
    color: $bodycolor;
    background-color:transparent;
    margin-top: 10px;
    border-radius: 5px;
    font-family: $font1;
    font-size: 2rem;
    padding: 5px 40px 5px 40px;
  }
//bg image (hidden mobile)
  &__bgimg {
    display: none;
    position:absolute;top:0;left:0;width:100%;height:100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.3;

    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  // content when BG img present
  &.slice--hasbg{
    background-color:$white;
    color: #fff;
    .slice__heading {
      color: #fff;
    }
    a.slice__btn {
        &:hover {
        background-color:darken($red,10%);
        }
    }
  

  &.slice--fullwidth {
    .slice__content--text {
      width: 100%;
      text-align: center;
    }    
  }
}
//image spacing
.slice__content--text + .slice__content--img, .slice__content--img + .slice__content--text {
  margin-top:20px;
}

}
//SLICE color options
.slice {
  @each $type in $colors {
    &.slice--#{nth($type, 1)} {
      color:            nth($type, 3);
      background-color: nth($type, 2);
      .slice__heading {
        color: nth($type, 4);
      }
      a.slice__btn {
        color: nth($type, 4);
        border:1px solid nth($type, 4);

      }
    }
  }
}
.slice--fullwidth {
  .container {
      padding-top: 45px;
      padding-bottom: 45px;
    }
}
.slice--background {
  background-color:#eee;
}
.slice__content--text + .slice__content--img, .slice__content--img + .slice__content--text {
  margin-top:20px;
}
.slice__contentinner {
  width: 100%;
}


//testimonials
  

.arrow-down {
  /* background-color:red; */
  width: 0; 
  height: 0;
  transform: rotate(150deg);
  border-left: 0px solid transparent;
  border-right: 90px solid transparent;
  
  border-top: 30px solid $white;
  position:absolute;
  bottom: 40px;
  left: 100px;
  /* z-index: 100; */
}  
  

  .slick-next.slick-arrow, .slick-prev.slick-arrow {
    display: none !important;
  }

  .slice--testimonial {
    background-color: $red;
   .container {
      padding-top: 60px;
      padding-bottom: 60px; }
    .slick-dots li button::before {
        border: 1px solid $red;
        background-color: $red;
        height: 10px;
        width: 10px;
    }

    .slick-dots li.slick-active button::before {
        background-color: #fff;
    }

    .slick-dots li.slick-active button::before {
        background-color:rgba($red,1);
    }
    .slick-dots li button::before {
        background-color:rgba($red,0.0);
    }

    .slick-dots li {
      margin:1px !important;
    }
  }



  .testimonial-container-in {
    background-color: #fff;
   
    max-width: 750px;
    margin:0 auto;
    padding:30px;
    
    position:relative;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    .slick-list {
      border-radius: 5px;
    }
  }





  .testimonial-slider {
    position: relative;
  }
  
  .testimonial {
    position: relative;
    height: 160px;
    display: table!important;
    width:100%;
    .testimonial-in {
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 20px;

    }
    .quote {
      display: block;
      margin: 0 auto;
      max-width: 600px;
      margin-top:15px;
    }
    .name {
      color: $red;
      display: block;
      font-size: 1.8rem;
    }
  }

//gallery 
.slice__gallery {
  ul {
    list-style: none;
    padding:0;
    margin:0;
    .slice__gallery-img {
      margin-bottom: 16px;
    }
  }
}


/*========== CTAS ==========*/

.container-cta {
  display: flex;
  flexwrap: nowrap;
  justify-content: center;
  align-items: center;

}

.cta {
  background-color:$orange;
  color:#fff;
  padding:10px 0;
  text-align: center;
  a {
    color: #fff;
    font-family: $font1;
    
  }
  &--images {
    text-align: center;
    .cta-logo-img {
      display: inline-block;
      max-width: 150px;
      padding:10px;
    }
  }
  
  &.cta--slider {
    .credit-banner {
      padding:0 45px;
    }
    .credit {
      padding:15px;
    }
    .slick-next, .slick-prev {
      &::before {
        color: #fff;
      }
    }
  }
}

//CTA COLOR OPTIONS
.cta {
  @each $type in $colors {
    &.cta--#{nth($type, 1)} {
      color:            nth($type, 3);
      background-color: nth($type, 2);
      a.cta__link {
        color: nth($type, 3);
      }
    }
  }
}

/*========== COOKIE NOTICE ==========*/
#cookie-notice-container {
	width: 100%;
	position: fixed;
	bottom: 0;
	background-color: $red;
	color: #fff;
	padding: 10px 0;
	font-size: 12px;
	z-index: 5;

p {
	margin-bottom: 0;
}

a {
	color: #fff;
	text-decoration: underline;
}

}

#cookie-notice-container .btn {
	display: block !important;
	margin-top: 5px;
  padding:0;
	width: 100%;
	border-radius: 0;
	background-color: $red;
}

#cookie-notice-container .btn:hover {
	color: #fff;
}

/*========== OUTDATED BROWSER NOTICE ==========*/
#browser-notice-container {
	width: 100%;
	background-color: #175caa;
	padding: 10px 0;
	z-index: 9;
	color: #fff;
}

#browser-notice-container p {
	width: 990px;
	margin: 0 auto;
	font-size: 18px;
	text-align: center;
	color: #fff;
}

#browser-notice-container a {
	color: #fff;
	text-decoration: underline;
}

#browser-notice-container button {
	margin: 0 10px;
	color: #fff;
}

#browser-notice-container i {
	margin-right: 5px;
}


/*========= PRODUCT =========*/
.page-container {
	margin: 30px 0;
}

.prod-title {
	margin-top: 0;
	margin-bottom: 15px;
}

.prod-code {
	margin-top: 0;
	font-weight: 300;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 15px;
}

.prod-rating {
	margin-bottom: 10px;
	color: #e7711b;
}

.prod-price {
	font-size: 30px;
	margin-bottom: 15px;
}

.color-details {
	margin-bottom: 15px;
}

.swatches {
	margin: 0.5em 0;
	padding-left: 0;
}

.swatches li {
	border: 2px solid #fff;
	display: inline-block;
	list-style: outside none none;
	margin: 2px 1px;
	vertical-align: top;
}

.swatches .selected, .swatches li:hover {
	border: 2px solid #b2b2b2;
}

.swatches a {
	background-position: center center;
	background-repeat: no-repeat;
	border: 2px solid #fff;
	box-shadow: 1px 1px 2px #c5c5c5;
	display: block;
	height: 33px;
	text-align: center;
	transition: all 0.25s ease 0s;
	width: 33px;
}

.swatches a img {
	height: 29px;
	width: 29px;
}

.prod-options {
	margin-bottom: 15px;
}

.prod-btns {
	margin-bottom: 15px;
}

.btn-buy {
	background-color: #4ec67f;
	font-size: 22px;
	color: #fff;
	font-weight: 300;
}

.prod-stock {
	text-transform: uppercase;
	font-weight: 300;
	margin-bottom: 15px;
}

.prod-stock i.green {
	color: #4ec67f;
}

/*========== PAGE - CART ==========*/
.cart-table th, .cart-table td {
	text-align: center;
	vertical-align: middle !important;
	padding: 15px !important;
	border-color: #eee !important;
}

.cart-table th {
	border-bottom: 1px solid #eee !important;
}

.cart-table .cart-description {
	text-align: left;
	position: relative;
}

.cart-table .cart-link {
	font-size: 11px;
	text-transform: uppercase;
	color: #bbb;
	transition-duration: 200ms;
	cursor: pointer;
}

.cart-table .cart-link:hover {
	text-decoration: none;
	color: #888;
}

.cart-table .cart-link:last-child {
	margin-left: 10px;
}

.cart-table .prod-title {
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: 500;
}

.cart-table .prod-code {
	font-weight: 300;
	margin-bottom: 10px;
}

.cart-table .prod-options {
	font-weight: 300;
}

.cart-table th {
	display: none;
}

.cart-table td {
	display: block;
}

.cart-table tr {
	margin-bottom: 15px;
	float: left;
	border: 1px solid #eee;
	border-top: 0;
}

.cart-table td.cart-table-quantity, .cart-table td.cart-table-price {
	display: table-cell;
	width: 50%;
}

.cart-table td.cart-table-products .img-responsive {
	max-width: 70%;
}

.btn-quant {
	color: #106489;
	background-color: #fff;
}

.btn-quant.plus {
	margin-left: 0 !important;
}

.cart-checkout-box {
	border: 1px solid #eee;
	padding: 15px;
	margin-bottom: 15px;
}

.cart-checkout-box h5 {
	font-weight: 300;
}

#cart-summary {
	width: 100%;
	margin-bottom: 10px
}

#cart-summary tr {
	border-bottom: 1px solid #eee;
}

#cart-summary tr:last-child {
	border-bottom: 0;
}

#cart-summary tr td {
	padding: 8px;
}

#cart-summary tr td:nth-child(2) {
	text-align: right;
}

#cart-summary .total-price {
	font-size: 24px;
	font-weight: 500;
}

/*========== PAGE - LOGIN ==========*/
.login-title {
	border-bottom: 1px solid #eee;
	font-size: 20px;
	font-weight: 300;
	margin-bottom: 15px;
	padding: 0 0 10px;
}

#login-guest-checkout {
	margin-top: 60px;
}

/*========== PAGE - CHECKOUT ==========*/
#same-address {
	background-color: #eee;
	padding: 5px 10px;
}

.cart-checkout-box h3 {
	margin: 10px 0 20px;
	font-size: 22px;
	font-weight: 400;
}

.checkout-flow {
	display: inline-block;
	width: 100%;
	text-align: center;
}

.checkout-flow ul {
	position: relative;
	display: inline-block;
	padding-top: 30px;
}

.checkout-flow ul::before {
	background-color: #eee;
	content: "";
	display: block;
	height: 1px;
	left: 43px;
	position: absolute;
	right: 30px;
	top: 20px;
}

.checkout-flow li {
	display: inline-block;
	padding: 10px;
	position: relative;
	width: 60px;
	text-align: center;
}

.checkout-flow li::before {
	background-color: #fff;
	border: 1px solid;
	border-radius: 20px;
	display: block;
	font-family: "FontAwesome";
	height: 30px;
	left: 50%;
	margin-left: -1em;
	padding: 4px 0;
	position: absolute;
	text-align: center;
	top: -25px;
	width: 30px;
}

.checkout-flow li.cart::before {
	content: "\f07a";
}

.checkout-flow li.checkout::before {
	content: "\f0c9";
}

.checkout-flow li.payment::before {
	content: "\f154";
}

.checkout-flow li.confirmation::before {
	content: "\f00c";
}

.checkout-flow li.active {
	color: #e7711b;
}

.checkout-flow li.complete {
	color: #4ec67f;
}

.flow-label {
	display: none;
}

/*========== PAGE - CHECKOUT CONFIRM ==========*/
.confirm-title {
	margin-top: 30px;
}

.confirm-social {
	margin: 30px 0;
}

.confirm-social i {
	font-size: 35px;
	color: #ccc;
}

/*========== PLUGIN - TOUCHSPIN ==========*/
.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 8px 10px;
	margin-left: -1px;
	position: relative;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0;
	border-top-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	margin-top: -2px;
	border-radius: 0;
	border-bottom-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 3px;
	left: 5px;
	font-size: 9px;
	font-weight: normal;
}

/*========== CONTACT PAGE ==========*/
#map-canvas {
	width: 100%;
	height: 350px;
}
.contact__box {
  background-color:$pink;
  border-bottom:8px solid $red;
  padding:15px;
  color: #fff;
   a {
    color: #fff;
   }
}




/*========== ACCOUNTS PAGES ==========*/
.account-box {
	border: 1px solid #eee;
	padding: 15px;
	margin-bottom: 15px;
}

.account-box h3 {
	font-size: 22px;
	font-weight: 400;
	margin: 10px 0 20px;
}

nav.account-menu {
	border: 1px solid #eee;
	border-radius: 10px;
}

.account-menu li {
	border-bottom: 1px solid #eee;
}

.account-menu li a {
	display: block;
	padding: 14px;
	transition-duration: 200ms;
}

.account-menu li a:hover {
	background-color: #eee;

}

.account-menu li.active a {
	background-color: #eee;
}

.account-menu li:last-child {
	border-bottom: 0;
}

.order-table th, .order-table td {
	vertical-align: middle !important;
	padding: 15px !important;
	border-bottom: 1px solid #eee !important;
}

/*========== TYPOGRAPHY ==========*/
.search--item {
  margin: 0 0 30px;
  .search--title {
    margin:0 0 10px;
    a {
      color:$red;
    }
  }
}


/*========== JOb FORM PAGE ==========*/
.advert-form {
  margin:30px 0 0;
  .btn {
    width:100%;
  }
}
.form--title {
    font-size: 2rem;
    margin:20px 0;
}

/* Small Devices, Tablets IPAD*/
@media only screen and (min-width: 768px) {

  /*========== TYPOGRAPHY ==========*/
  /*========== BTNS ==========*/
  /*========== FORMS ==========*/
  /*========== HELPERS ==========*/
  /*========== GLOBAL CONTAINERS ==========*/
  .ctn {
    padding:75px 0;
  }


  /*========== HEADER ==========*/


 //header transparency and size
 .headv1 {

   .logo-holder {
      background-image:url('/img/logo_mob.png');
      width: 147px;
      height: 83px;
      margin:5px 0;
   }


   &.headv1--transparent {
     background-color:transparent;
     .logo-holder {
       background-image:url('/img/logo.png');
       width: 195px;
       height: 110px;
       float:left;
       margin:10px 0;
     }
     .headv1__topmenu--div3 {
      opacity: 0;
     }
     .headv1__dropdown--search {
      margin-top:-30px;
     }
   }


 }

 .stuck {
   .headv1 {
     z-index:999;
     position:relative;
     .logo-holder {
       background-image:url('/img/logo_mob.png');
       width: 147px;
       height: 83px;
       margin:5px 0;
     }
     .headv1__topmenu--div3 {
      opacity: 1;
     }
     .headv1__dropdown--search {
      margin-top:2px;
     }
   }
 }


  .headv1__topmenu {
    height:80px;
    width: 100%;
    ul {
        //width: 160px;
        li {
          > a {
              font-size: 2.4rem;
          }
       }
    }
  }


.headv1__bottom {
    display: table;
    width: 100%;
    background-color:$blue;
    border-bottom:1px solid rgba(#fff,0.1);
  }
  .headv1__bottommenu {
    margin: 0 auto;
    max-width: 1200px;
    .navbar-nav {
      margin: 0;
      display: table;
      width: 100%;
      table-layout: fixed;
      position:relative;
      > li {
        float: none;
        display: table-cell;
        width: 100%; //fill space
        height: 100%;
        vertical-align: top;
        position:static;
      > a {
        padding:14px;
        height: 100%;
        display: table;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 1.6rem;
        &::after {
          content: ""; position:absolute; 
          top:10%;
          right:0;
          height: 80%;
          width: 2px;
          background-color:rgba(#fff,0.1);
        }

        &:focus, &:hover, &:active {
          background-color:$blue;
        }
        span {
          display: table-cell;
          height: 100%;
          vertical-align: middle;
        }
      }
      &:last-child a::after {display:none;}
      &.open {
        > a{
          background-color:$blue!important;
        }
        .dropdown-menu {
          width: 100%;
          border:none;
          background-color:$blue;
          padding:20px 30px;
          border-top:1px solid rgba(#fff,0.1);
          box-shadow:none;
          color: #fff;
          a {
            color: #fff;
          }
            .dropdown-menu__submenu {
              padding:0;
              margin:0;
              list-style: none;
              li {
                margin:0 0 5px;
                a {
                  display: block;
                  text-decoration: none!important;
                  //position:relative;
                  &::before {
                    font-family: 'FontAwesome';
                    content: "\f105";
                    color: #fff;
                    margin-right: 10px;
                  }
                }
                &:last-child {
                  margin:0;
                }
              }
            }
        }
      }
      }
    }
  }
  .dropdown-menu__header {
    margin:0 0 20px;
    font-size: 2.6rem;
  }
  .dropdown-menu__title {
    table-layout: auto;
    padding:0 0 20px;
    border-bottom:1px solid rgba(#fff,0.1);
    margin:0 0 20px;
    &--icon {
      width: 50px;
    }
    &--text {

    }
    &--arrow {
      text-align: right;
    }
  }
  /*========== BANNERS ==========*/
  .banner{
    height: 520px;
  }
  .banner__content {
    h3 {
      font-size: 4rem;
    }
    p{
      font-size: 1.6rem;
    }
    .container {
    width: 500px;
  }
  }
  /*========== FEATURED ITEMS ==========*/


.fl__row {

  
}


.ctn--feat {
  .fl__row:last-child {
    border-bottom:2px solid rgba(255,255,255,0.2);
  }
  .fi {
    //margin: 0 0 15px;     
    .fi__content {   
      //border-bottom:2px solid rgba(255,255,255,0.2);
      //border-right:2px solid rgba(255,255,255,0.2);
      padding:15px 15px 30px;
      width: 100%;     
    }
    &:nth-child(3n+1) {
      border-right: 2px solid rgba(255,255,255,0.2);
    }

     &:nth-child(3n+2) {
      border-right: 2px solid rgba(255,255,255,0.2);
    }
    // &:nth-child(3n+3) > div {
    //   border-right: 0;
    // }
    // &:last-child > div {
    //   border-right: 0;
    // }
   
    // &:nth-child(1) > div {
    //     border-top:0px;
    //     border-left:0px;
    //     border-right:0px;
    //     }
    //   &:nth-child(2) > div {
    //     border-top: 0px;
    //     }
    //   &:nth-child(3) > div {
    //     border-left:0px;
    //     border-right:0px;
    //     border-top:0px;
    //   }
    //   &:nth-child(4) > div {
    //     border:0px;
    //   }
    //   &:nth-child(5) > div {
    //     border-top:0px;
    //     border-bottom:0px;
    //   }
    //   &:nth-child(6) > div {
    //       border:0;
    //   }  
  }
}






  
  .fi {
    .fi__heading {
      font-size: 1.8rem;   
    }  
  }
  /*========== FOOTER ==========*/
  .footer-top__logo {
    width: 150px;
  }

  /*========== HOME ==========*/
  /*========== CATEGORY ==========*/
  .category-main {
    padding:45px 0;
  }
  .category__right {
    .job__apply-button{
      margin-top:30px;
  }
  }
  /*========== ARTICLE ==========*/
  .job__details {
    font-size: 1.8rem;
    margin-bottom:30px;
    font-family: $font1;
  }
  .article-sidebar {
    margin-top:0;
  }

  .apply-btn {
    width: auto;
    margin:30px 0 0;
  }
  /*========== SLICES ==========*/
  
.slice {
    padding:0;
    //background image
    &__bgimg {
      display: block;
    }
    &.slice--hasbg{
      background-color:$red;
      color: #fff;
      //content styles for background except full width
      .slice__container.slice--hasimg {
        position:relative;
        z-index: 9;
        .slice__content--text {
          background-color:rgba($red,0.75);
          color: #fff;
          padding:30px;
        }

      }
      .slice__heading {
        color: #fff;
      }
      a.slice__btn {
      }
    }
  }
  .slice__container {
    display:flex;
    width:100%;
    min-height: 350px;

    // IMAGE RIGHT use flexbox to align image right (so image always on top for mob)
    &.slice--imgright {
      .slice__content--text {
        order:1;
      }
      .slice__content--img {
        order:2;
        padding:60px 0px 60px 60px;
      }
    }
    
    // general
    .slice__content {
      display: flex;
      align-items:center;
    }
    .slice__content--text {
      padding:60px 0 60px 0;
      width: 65%;
    }
    .slice__content--img {
      padding:60px 60px 60px 0;
      width: 35%;
    }
  }
  //spacing
  .slice__content--text + .slice__content--img, .slice__content--img + .slice__content--text {
    margin-top:0;
  }
















  // .slice {
  //   padding:60px 0;
  //   .container {
  //      width: 992px;
  //    }
  // }
  // .slice__container {
  //   display:table;
  //   width:100%;
  //   table-layout:fixed;
  //   .slice__content {
  //     display: table-cell;
  //     vertical-align: middle;
  //   }
  //   .slice__content--text {
  //     width: 65%;
  //     padding:60px 0 60px 0;
  //   }
  //   .slice__content--img {
  //     width: 35%;
  //   }
  //   .slice__content--img img {
  //     max-width: 75%;
  //   }
  // }
  // .slice__content--text + .slice__content--img, .slice__content--img + .slice__content--text {
  //   margin-top:0;
  // }



  //testimonials
    .testimonial-slider {
      position: relative;
      padding:0 30px;
    }
    .testimonial {
      .name {
        font-size: 3rem;
      }
    }

  /*========== CTAS ==========*/
  .cta {
    font-size:2.6rem;
    padding:10px 0;
    a {
      color: #fff;
      text-decoration: none;

    }
  }
	
	#cookie-notice-container p {
		font-size: 14px;
		text-align: center;
	}
	/*========== PRODUCT =========*/
	/*========== PAGE - CART ==========*/
	.cart-table th {
		display: table-cell;
	}

	.cart-table td {
		display: table-cell;
	}

	.cart-table-products {
		max-width: 300px;
		display: table-cell;
	}

	.confirm-table-products {
		max-width: 100px;
		display: table-cell;
	}

	.cart-table tr {
		margin-bottom: 0;
		float: none;
		border: none;
		border-top: auto;
	}

	.cart-table td.cart-table-quantity, .cart-table td.cart-table-price {
		display: table-cell;
		width: auto;
	}

	.cart-table .cart-description {
		min-width: 300px;
	}

	.cart-table td.cart-table-products .img-responsive {
		max-width: 100%;
	}

	/*========== PAGE - LOGIN ==========*/
	/*========== PAGE - CHECKOUT ==========*/
	.checkout-flow {
		margin-bottom: 30px;
	}

	.checkout-flow li {
		width: 100px;
	}

	.checkout-flow ul::before {
		right: 50px;
	}

	.flow-label {
		display: block;
	}

	/*========== PAGE - CHECKOUT CONFIRM ==========*/
	/*========== PLUGIN - TOUCHSPIN ==========*/
	/*========== CONTACT PAGE ==========*/
  #map-canvas {
    width: 100%;
    height: 400px;
  }
  .contact__box {
    padding:30px;
    font-size: 1.8rem;
  }


  /*========== JOb FORM PAGE ==========*/
  .advert-form {
    margin:30px 0 0;
    .btn {
      width:auto;
    }
  }
  .form--title {

  }



	/* Medium Devices, Desktops */
}

@media only screen and (min-width: 992px) {
	/*========== BTNS ==========*/
	/*========== FORMS ==========*/
	/*========== HELPERS ==========*/

  .pink-boxes {
    flex-wrap: nowrap;
    padding: 15px 15px;
  }

  .arrow-down {
  /* background-color:red; */
  
  left: 160px;
  /* z-index: 100; */
}  
	/*========== GLOBAL TEXT STYLES ==========*/
	/*========== GLOBAL CONTAINERS ==========*/
	/*========== SYRUP LOGIN ==========*/
	/*========== SYRUP ADMIN BAR ==========*/
	/*========== COOKIE NOTICE ==========*/
	#cookie-notice-container .btn {
		display: inline-block !important;
		width: auto;
		float: right;
		margin-top: 0;
		font-size: 16px;
    background-color: $pink;
    border-radius: 40px;
	}
	/*========== OUTDATED BROWSER NOTICE ==========*/
	/*========== HEADER ==========*/
	/*========== FOOTER ==========*/
	/*========== HOME ==========*/

  .pink-boxes__item__link__container {
    span {
      font-size: 1.4rem;
    }
  }
	/*========== CATEGORY ==========*/
	/*========== EVENT ==========*/
	/*========== ARTICLE ==========*/

 
	/*========== SLICES ==========*/
	/*========== CTAS ==========*/
	/*========== PRODUCT =========*/
	/*========== PAGE - CART ==========*/
	/*========== PAGE - LOGIN ==========*/
	/*========== PAGE - CHECKOUT ==========*/
	/*========== PAGE - CHECKOUT CONFIRM ==========*/
	/*========== PLUGIN - TOUCHSPIN ==========*/

/* Large Devices, Wide Screens */
}
@media only screen and (min-width: 1200px) {
	/*========== BTNS ==========*/
	/*========== FORMS ==========*/
	/*========== HELPERS ==========*/
	/*========== GLOBAL TEXT STYLES ==========*/
	/*========== GLOBAL CONTAINERS ==========*/


  header {
    .container {
      width: 1170px;
    }
  }

  footer {
    .container {
      width: 1170px;
    }
  }

  .container {
    width: 970px ;
  }

  .container--large {
  width: 1170px;
}



.category-get-job-container {
    width: 1170px;
  }
	/*========== SYRUP LOGIN ==========*/
	/*========== SYRUP ADMIN BAR ==========*/
	/*========== COOKIE NOTICE ==========*/
	/*========== OUTDATED BROWSER NOTICE ==========*/
	/*========== HEADER ==========*/
  .headv1__bottommenu {
    .navbar-nav {
      > li {
        position:relative;
        &.right {
          .dropdown-menu {
            left:auto;
            right: 0;
          }
        }
        &.open {
          .dropdown-menu {
            width: 500px;
          }
        }
      }
    }
  }
	/*========== FOOTER ==========*/
	/*========== HOME ==========*/
	/*========== CATEGORY ==========*/
	/*========== EVENT ==========*/
	/*========== ARTICLE ==========*/
	/*========== SLICES ==========*/
	/*========== CTAS ==========*/
	/*========== PRODUCT =========*/
	/*========== PAGE - CART ==========*/
	/*========== PAGE - LOGIN ==========*/
	/*========== PAGE - CHECKOUT ==========*/
	/*========== PAGE - CHECKOUT CONFIRM ==========*/
	/*========== PLUGIN - TOUCHSPIN ==========*/
	/*mobile only*/
}

@media only screen and (max-width: 477px) {
  .ctn--feat {    
    .fl__item {
      width: 100%;
    }
  }

  .sticky-wrapper .headv1 {
    position:relative!important;
  }
}

@media only screen and (max-width: 550px) {

    .category__tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    h5 {
      
      padding: 10px 10px;
      display: inline;
      font-size: 1.4rem;
    }
  }
}

@media only screen and (max-width: 707px) {
  .fl__row {
    //justify-content: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .article__right-side {
  padding-top: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .fi__content {   
      border:2px solid rgba(255,255,255,0.2);
      padding:15px;
      width: 100%; 
      border-left:0;
      border-right:0;   
      border-top:0; 
    }





	/*anti sticky js for mobile*/
	#checkoutBox {
		position: relative !important;
	}

  .pink-boxes__item__link__container {
    span {
      font-size: 1.2rem;
    }
  }
}

/*========== ANIMATIONS ==========*/
.flash {
	-webkit-animation-name: flash-animation;
	-webkit-animation-duration: 0.5s;

	animation-name: flash-animation;
	animation-duration: 0.5s;
}

@-webkit-keyframes flash-animation {
	from {
		background: #106489;
	}
	to {
		background: default;
	}
}

@keyframes flash-animation {
	from {
		background: #106489;
	}
	to {
		background: default;
	}
}

