//fonts
$font1:'Raleway', sans-serif;
$font2:'Open Sans', sans-serif;
$font3:'Quicksand', sans-serif;
$bodycolor:#000;
//colors
$white:#fff;
$red: #C92838;
$pink: #E1495E;
$blue: #337ab7;
$orange: #EF7E00;
$grey: #EEEEEE;

// name, background, text-color, heading color
$colors:(
  "white" #fff $bodycolor $red,
  "red" $red $white $white,
  "pink" $pink #fff $white,
  "orange" $orange #fff $white,
  "grey" $grey $bodycolor $red,
);

//mixins

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin flexwrap($wrap) {
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;
}